.rmdb-grid h1 {
  font-family: 'Abel', sans-serif;
  font-size:42px;
}

.rmdb-grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.rmdb-grid-element {
  background: #353535;
  max-height: 430px;
  animation: animateGrid 0.5s;
  overflow: hidden;
}

@keyframes animateGrid {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@media screen and (max-width: 720px) {
  .rmdb-grid-content {
    grid-template-columns: auto auto;
  }

  .rmdb-grid-element:nth-child(2n+2) {
    margin-right: 0;
  }

  .rmdb-grid h1 {
    font-size:22px;
  }
}