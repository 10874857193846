.rmdb-movieinfobar {
  width: 100%;
  height: 105px;
  background: #1c1c1c;
  position: relative;
  padding: 25px 20px 0px 20px;
  box-sizing: border-box;
  font-family: 'Abel', sans-serif;
  font-size: 22px;
}

.rmdb-movieinfobar-content {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}

.rmdb-movieinfobar-content-col {
  float: left;
  width: 33.33%;
  box-sizing: border-box;   
  padding: 10px 20px 0 0;
}

.rmdb-movieinfobar-info {
  padding: 5px 0 0 0px;
  float: left;
}

.fa-time, .fa-revenue {
  float: left;
  margin-top: -4px;

}

.fa-budget {
  float: left;
  margin-top: -3px;
}